<template lang="pug">
sync-grid#overviewgrid-dashboard(ref="overviewgrid" :uid="uid" :gridData="data" :key="key" :name="name" v-if="dataLoaded" :toolbar='toolbar' :headerData="headerData" :frozenColumns="frozenColumns" :allowGrouping="allowGrouping" :allowKeyboard="allowKeyboard" :tagguerButton="tagguerButton" :editSettings="editSettings" style="border: 0px !important;" :saveGridStateProps="false")
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatCurrency,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import CellRendererColorCurrency from "@/components/global/ag-grid/CellRendererColorCurrency.vue";

export default {
  components: {
    SyncGrid,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dateRange: {
      type: Object,
      default: {
        startDate: new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        endDate: new Date(
          dayjs().endOf("month").format("YYYY-MM-DD")
        ).toISOString(),
      },
    },
    viewType: {
      type: String,
      default: "amount",
    },
    viewMode: {
      type: String,
      default: "compare",
    },
    viewSlot: {
      type: String,
      default: "global",
    },
  },
  data() {
    return {
      title: "Suivi par collaborteur",
      name: "supervisionByCollabortors",
      uid: "globalGridDashboard",
      dataLoaded: false,
      key: 0,

      tagguerButton: false,
      allowKeyboard: false,
      allowGrouping: false,
      frozenColumns: 1,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
      },
      headerLoaded: false,
      toolbar: [
        { text: "Search", align: "Right" },
        {
          text: "Imprimer",
          prefixIcon: "e-print",
          id: "overviewgrid-dashboard_print",
          align: "Right",
        },
        {
          text: "Exporter en PDF",
          prefixIcon: "e-pdfexport",
          id: "overviewgrid-dashboard_pdfexport",
          align: "Right",
        },
        {
          text: "Exporter en Excel",
          prefixIcon: "e-excelexport",
          id: "overviewgrid-dashboard_excelexport",
          align: "Right",
        },
        {
          text: "Exporter en CSV",
          prefixIcon: "e-csvexport",
          id: "overviewgrid-dashboard_csvexport",
          align: "Right",
        },
        "ColumnChooser",
      ],
      colorCurrencyTemplate() {
        return {
          template: CellRendererColorCurrency,
        };
      },
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
    },
    viewMode: function (newVal, oldVal) {
      this.dataLoaded = false;
      this.setHeaderData();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
    },
    viewType: function (newVal, oldVal) {
      this.dataLoaded = false;
      this.setHeaderData();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
    },
  },
  async mounted() {
    this.setHeaderData();
    this.dataLoaded = true;
  },
  methods: {
    ...mapGetters(["affairsList"]),
    formatNumber,
    setHeaderData() {
      // On génère les colonnes des mois
      let dateDebut = new Date(this.dateRange.startDate);
      let dateFin = new Date(this.dateRange.endDate);
      let dateActuelle = new Date(dateDebut);
      let columnsDetailMonths = [];

      while (dateActuelle <= dateFin) {
        let dateStr = dayjs(dateActuelle).startOf("month").format("MM_YYYY");
        let dateFormated = dayjs(dateActuelle)
          .startOf("month")
          .format("MMMM YYYY");
        let columnsMonth = {
          headerText:
            dateFormated.charAt(0).toUpperCase() + dateFormated.slice(1),
          customCss: "text-primary",
          customAttributes: { class: "text-primary" },
          columns: [
            {
              mode: "compare",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              headerText: "Chiffre d'Affaires",
              customCss: "text-primary",
              customAttributes: {
                class: [
                  "bg-light-grey",
                  "e-column-border-right-light",
                  "text-primary",
                ],
              },
              columns: [
                {
                  mode: "forecast",
                  viewType: "amount",
                  visible:
                    (this.viewMode == "compare" ||
                      this.viewMode == "forecast") &&
                    this.viewType == "amount"
                      ? true
                      : false,
                  type: "number",
                  field: "amountOrderFormsCustomersSentByMonth",
                  label: "Objectif",
                  headerText: "Objectif",
                  width: 140,
                  minWidth: 140,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "right",
                  valueAccessor: function (field, data, column) {
                    return data[field] > 0 ? formatCurrency(data[field]) : "--";
                  },
                  customAttributes: {
                    class: [
                      "bg-light-grey",
                      "font-weight-bolder",
                      "text-warning",
                    ],
                  },
                },
                {
                  mode: "counted",
                  viewType: "amount",
                  visible:
                    (this.viewMode == "compare" ||
                      this.viewMode == "counted") &&
                    this.viewType == "amount"
                      ? true
                      : false,
                  type: "number",
                  field: dateStr + "_orderFormsCustomersSentAmount",
                  label: "Réalisé",
                  headerText: "Réalisé",
                  width: 140,
                  minWidth: 140,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "right",
                  valueAccessor: function (field, data, column) {
                    return data[field] > 0 ? formatCurrency(data[field]) : "--";
                  },
                  customAttributes: {
                    class: [
                      "bg-light-grey",
                      "font-weight-bolder",
                      "text-primary",
                    ],
                  },
                },
                {
                  mode: "compare",
                  viewType: "amount",
                  visible:
                    this.viewMode == "compare" && this.viewType == "amount"
                      ? true
                      : false,
                  type: "number",
                  field: dateStr + "_amountOrderFormsCustomersSentPercent",
                  label: "%",
                  headerText: "%",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  valueAccessor: function (field, data, column) {
                    return data["amountOrderFormsCustomersSentByMonth"] > 0
                      ? formatNumber(
                          (data[dateStr + "_orderFormsCustomersSentAmount"] /
                            data["amountOrderFormsCustomersSentByMonth"]) *
                            100
                        ) + " %"
                      : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
                  },
                  customAttributes: {
                    class: [
                      "bg-light-grey",
                      "font-weight-bolder",
                      "e-column-border-right-light",
                    ],
                  },
                },
              ],
            },
            {
              mode: "compare",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              headerText: "Contacts Utiles",
              customCss: "text-primary",
              customAttributes: {
                class: ["e-column-border-right-light", "text-primary"],
              },
              columns: [
                {
                  mode: "forecast",
                  viewType: "all",
                  visible:
                    this.viewMode == "compare" || this.viewMode == "forecast"
                      ? true
                      : false,
                  type: "number",
                  field: "numbersActivitiesByMonth",
                  label: "Objectif",
                  headerText: "Objectif",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  valueAccessor: function (field, data, column) {
                    return data[field] > 0 ? data[field] : "--";
                  },
                  customAttributes: {
                    class: ["font-weight-bolder", "text-warning"],
                  },
                },
                {
                  mode: "counted",
                  viewType: "all",
                  visible:
                    this.viewMode == "compare" || this.viewMode == "counted"
                      ? true
                      : false,
                  type: "number",
                  field: dateStr + "_activity",
                  label: "Réalisé",
                  headerText: "Réalisé",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  valueAccessor: function (field, data, column) {
                    return data[field] > 0 ? data[field] : 0;
                  },
                  customAttributes: {
                    class: ["font-weight-bolder", "text-primary"],
                  },
                },
                {
                  mode: "compare",
                  viewType: "all",
                  visible: this.viewMode == "compare" ? true : false,
                  type: "number",
                  field: dateStr + "_totalActivitiesPercent",
                  label: "%",
                  headerText: "%",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  valueAccessor: function (field, data, column) {
                    return data["numbersActivitiesByMonth"] > 0
                      ? formatNumber(
                          (data[dateStr + "_activity"] /
                            data["numbersActivitiesByMonth"]) *
                            100
                        ) + " %"
                      : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
                  },
                  customAttributes: {
                    class: [
                      "font-weight-bolder",
                      "e-column-border-right-light",
                    ],
                  },
                },
              ],
            },
            {
              mode: "compare",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              headerText: "Devis vs Commande",
              customCss: "text-primary",
              customAttributes: {
                class: ["e-column-border-right-light", "text-primary"],
              },
              columns: [
                {
                  mode: "all",
                  viewType: "all",
                  visible: true,
                  type: "number",
                  field: dateStr + "_quotesSentNumbers",
                  label: "Nb Devis envoyés",
                  headerText: "Nb Devis envoyés",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  customAttributes: {
                    class: ["font-weight-bolder", "text-primary"],
                  },
                },
                {
                  mode: "all",
                  viewType: "all",
                  visible: true,
                  type: "number",
                  field: dateStr + "_orderFormsCustomersSentNumbers",
                  label: "Nb Commandes validées",
                  headerText: "Nb Commandes validées",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  customAttributes: {
                    class: ["font-weight-bolder", "text-primary"],
                  },
                },
                {
                  mode: "all",
                  viewType: "all",
                  visible: true,
                  type: "number",
                  field: dateStr + "_percentTransfo",
                  label: "% Transfo",
                  headerText: "% Transfo",
                  width: 90,
                  minWidth: 90,
                  allowEdit: false,
                  allowFiltering: false,
                  allowSorting: true,
                  showInColumnChooser: true,
                  textAlign: "center",
                  valueAccessor: function (field, data, column) {
                    return data[dateStr + "_quotesSentNumbers"] > 0
                      ? formatNumber(
                          (data[dateStr + "_orderFormsCustomersSentNumbers"] /
                            data[dateStr + "_quotesSentNumbers"]) *
                            100
                        ) + " %"
                      : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
                  },
                  customAttributes: {
                    class: [
                      "font-weight-bolder",
                      "e-column-border-right-light",
                    ],
                  },
                },
              ],
            },
          ],
        };

        columnsDetailMonths.push(columnsMonth);

        if (dateActuelle.getMonth() === 11) {
          dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
          dateActuelle.setMonth(0);
        } else {
          dateActuelle.setMonth(dateActuelle.getMonth() + 1);
        }
      }

      this.headerData = [
        {
          type: "string",
          field: "collaboratorName",
          headerText: "Collaborateur",
          direction: "Ascending",
          width: 300,
          minWidth: 100,
          allowEdit: false,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "left",
        },
        {
          type: "string",
          field: "job",
          headerText: "Fonction",
          width: 160,
          minWidth: 160,
          allowEdit: false,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "left",
          customAttributes: { class: ["e-column-border-right-light"] },
        },
        {
          mode: "compare",
          visible:
            this.viewMode == "compare" || this.viewMode == "counted"
              ? true
              : false,
          headerText: "Chiffre d'Affaires TOTAL",
          customAttributes: {
            class: [
              "text-primary",
              "e-column-border-right-light",
              "bg-light-grey",
            ],
          },
          columns: [
            {
              mode: "forecast",
              viewType: "amount",
              visible:
                (this.viewMode == "compare" || this.viewMode == "forecast") &&
                this.viewType == "amount"
                  ? true
                  : false,
              type: "number",
              field: "amountOrderFormsCustomersSentByPeriod",
              label: "Objectif",
              headerText: "Objectif",
              width: 140,
              minWidth: 140,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return data[field] > 0 ? formatCurrency(data[field]) : "--";
              },
              customAttributes: {
                class: ["bg-light-grey", "font-weight-bolder", "text-warning"],
              },
            },
            {
              mode: "counted",
              viewType: "amount",
              visible:
                (this.viewMode == "compare" || this.viewMode == "counted") &&
                this.viewType == "amount"
                  ? true
                  : false,
              type: "number",
              field: "amountOrderFormsCustomersSent",
              label: "Réalisé",
              headerText: "Réalisé",
              width: 140,
              minWidth: 140,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return data[field] > 0 ? formatCurrency(data[field]) : "--";
              },
              customAttributes: {
                class: ["bg-light-grey", "font-weight-bolder", "text-primary"],
              },
            },
            {
              mode: "compare",
              viewType: "amount",
              visible:
                this.viewMode == "compare" && this.viewType == "amount"
                  ? true
                  : false,
              type: "number",
              field: "amountOrderFormsCustomersSentPercent",
              label: "%",
              headerText: "%",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              valueAccessor: function (field, data, column) {
                return data["amountOrderFormsCustomersSentByPeriod"] > 0
                  ? formatNumber(
                      (data["amountOrderFormsCustomersSent"] /
                        data["amountOrderFormsCustomersSentByPeriod"]) *
                        100
                    ) + " %"
                  : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
              },
              customAttributes: {
                class: [
                  "bg-light-grey",
                  "font-weight-bolder",
                  "e-column-border-right-light",
                ],
              },
            },
          ],
        },
        {
          mode: "compare",
          visible:
            this.viewMode == "compare" || this.viewMode == "counted"
              ? true
              : false,
          headerText: "Contacts Utiles TOTAL",
          customCss: "text-primary",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "forecast",
              viewType: "all",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "numbersActivitiesByPeriod",
              label: "Objectif",
              headerText: "Objectif",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              valueAccessor: function (field, data, column) {
                return data[field] > 0 ? data[field] : "--";
              },
              customAttributes: {
                class: ["font-weight-bolder", "text-warning"],
              },
            },
            {
              mode: "counted",
              viewType: "all",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "totalActivities",
              label: "Réalisé",
              headerText: "Réalisé",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              valueAccessor: function (field, data, column) {
                return data[field] > 0 ? data[field] : 0;
              },
              customAttributes: {
                class: ["font-weight-bolder", "text-primary"],
              },
            },
            {
              mode: "compare",
              viewType: "all",
              visible: this.viewMode == "compare" ? true : false,
              type: "number",
              field: "totalActivitiesPercent",
              label: "%",
              headerText: "%",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              valueAccessor: function (field, data, column) {
                return data["numbersActivitiesByPeriod"] > 0
                  ? formatNumber(
                      (data["totalActivities"] /
                        data["numbersActivitiesByPeriod"]) *
                        100
                    ) + " %"
                  : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
              },
              customAttributes: {
                class: ["font-weight-bolder", "e-column-border-right-light"],
              },
            },
          ],
        },
        {
          mode: "compare",
          visible:
            this.viewMode == "compare" || this.viewMode == "counted"
              ? true
              : false,
          headerText: "Devis vs Commande",
          customCss: "text-primary",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "all",
              viewType: "all",
              visible: true,
              type: "number",
              field: "totalQuotesSent",
              label: "Nb Devis envoyés",
              headerText: "Nb Devis envoyés",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              customAttributes: {
                class: ["font-weight-bolder", "text-primary"],
              },
            },
            {
              mode: "all",
              viewType: "all",
              visible: true,
              type: "number",
              field: "totalOrderFormsCustomersSent",
              label: "Nb Commandes validées",
              headerText: "Nb Commandes validées",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              customAttributes: {
                class: ["font-weight-bolder", "text-primary"],
              },
            },
            {
              mode: "all",
              viewType: "all",
              visible: true,
              type: "number",
              field: "percentTransfo",
              label: "% Transfo",
              headerText: "% Transfo",
              width: 90,
              minWidth: 90,
              allowEdit: false,
              allowFiltering: false,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              valueAccessor: function (field, data, column) {
                return data["totalQuotesSent"] > 0
                  ? formatNumber(
                      (data["totalOrderFormsCustomersSent"] /
                        data["totalQuotesSent"]) *
                        100
                    ) + " %"
                  : "--"; // data[field]>0 ? formatCurrency(data[field]) : "--"
              },
              customAttributes: {
                class: ["font-weight-bolder", "e-column-border-right-light"],
              },
            },
          ],
        },
        {
          headerText: "Suivi mensuel",
          customCss: "text-primary",
          customAttributes: { class: "text-primary" },
          columns: columnsDetailMonths,
        },
      ];

      this.headerLoaded = true;
    },
  },
};
</script>
<style>
#overviewgrid-dashboard_toolbarItems {
  padding-top: 42px !important;
}
</style>
