<template lang="pug">
span
    div(v-if="data[data.column.field] !== 0 && data[data.column.field] !== null")
        span.mr-50 {{ formatCurrency(data[data.column.field]) }}
        span
            .material-icons.text-danger.small(v-if="data.column.field.slice(-15)=='totalOfExpenses'") north    
            .material-icons.text-success.small(v-else) south   
    .d-flex.w-100.justify-content-center.align-items-center(v-else)
        | --
</template>
<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
    data() {
        return {
            data: null,
            field: null
        };
    },
    methods:{
        formatCurrency,
    }
}
</script>
