<template lang="pug">
.h-100(v-if="dataLoaded") 
    ejs-accumulationchart(:ref="name" :title='title' height='100%' ref='pie' style='display:block;' :theme="'Bootstrap5'" :legendSettings='legendSettings' :tooltip='tooltip' selectionMode='Point' :enableBorderOnMouseMove='false' )
        e-accumulation-series-collection
            e-accumulation-series(:dataSource='data' xName='x' yName='y' :startAngle='startAngle' :endAngle='endAngle' innerRadius='43%' :dataLabel='dataLabel' :pointColorMapping='"fill"')
</template>

<script>
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  AccumulationSelection,
  Selection,
  ChartAnnotation
} from "@syncfusion/ej2-vue-charts";
import { Browser } from '@syncfusion/ej2-base';

export default {
    components: {
        'ejs-accumulationchart': AccumulationChartComponent,
        'e-accumulation-series-collection': AccumulationSeriesCollectionDirective,
        'e-accumulation-series': AccumulationSeriesDirective
    },
    provide: {
        accumulationchart: [AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation]
    },
    props:{
        data:{
            type:Array,
            default: () => []
        },
        viewMode:{
            type:String,
            default:"compare"
        }
    },
    data() {
        return {
            title: "",
            name: "pieChartActivitiesByCollaborators",
            dataLoaded:false,
            legendSettings: {
                visible: false,
                toggleVisibility: false,
                position: Browser.isDevice ? "Bottom" : "Right",
                textWrap:'Wrap', height: Browser.isDevice ? '20%' : '30%', width: Browser.isDevice ? '80%' :'20%',
                maximumLabelWidth: 66  
            },
            dataLabel: {
                name: 'x',
                visible: true,
                font: {
                fontWeight: '600',
                color: '#ffffff'
                }
            },
            startAngle: 0,
            endAngle: 360,
            tooltip: {
                enable: true,
                format:  '<b>${point.x}</b><br><b>Contacts utiles : ${point.y}</b>',
                header:""  
            },
            annotations:[{
                content: Browser.isDevice ? " " : "<div style='font-Weight:600;font-size:14px; text-align:center'>Contacts<br>utiles</div>",
                region: 'Series',
                x: '52%',
                y: '50%'
                }
            ]
        }
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
};
</script>