<template lang="pug">
#arc-gauge.h-100(v-if="dataLoaded") 
    ejs-chart(:ref='"chart_"+name' :id='"chart_"+name' style='display:block' theme='bootstrap5' align='center' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea' :width='width' :tooltip='tooltip' :legendSettings='legend')
        e-series-collection
            e-series(:dataSource='data' fill="#E0DF00" type='Bar' xName='collaboratorName' yName='totalActivities' name='Nombre de contacts utiles' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')   
            e-series(:dataSource='data' fill="#3F5899" type='Bar' xName='collaboratorName' yName='totalOrderFormsCustomersSent' name='Nombre de commandes validées' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')   
            e-series(:dataSource='data' fill="#00A7A6" type='Bar' xName='collaboratorName' yName='totalQuotesSent' name='Nombre de devis envoyés' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')  
            e-series(:dataSource='data' fill="#00A7A6" type='Bar' xName='collaboratorName' yName='totalQuotesSigned' name='Nombre de devis signés' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')  
            e-series(:dataSource='data' fill="#0C3571" type='Bar' xName='collaboratorName' yName='totalInvoicesSent' name='Nombre de factures émises' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')  
</template>
<script>
import { mapGetters } from "vuex";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  BarSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-vue-charts";

export default {
  components: {
    "ejs-chart": ChartComponent,
    "e-series-collection": SeriesCollectionDirective,
    "e-series": SeriesDirective,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "chartRankingGoalsByCollaborators",
    },
    height: {
      type: Number,
      default: 100,
    },
    viewMode: {
      type: String,
      default: "compare",
    },
  },
  data: function () {
    return {
      title: "",
      dataLoaded: false,
      dataSource: [],
      //Initializing Primary X Axis
      primaryXAxis: {
        valueType: "Category",
        majorGridLines: { width: 0 },
      },

      //Initializing Primary Y Axis
      primaryYAxis: {
        labelFormat: "{value}",
        edgeLabelPlacement: "Shift",
        majorTickLines: { width: 0 },
        lineStyle: { width: 0 },
      },
      chartArea: {
        border: {
          width: 0,
        },
      },

      marker: {
        dataLabel: {
          visible: false,
          position: "Top",
          name: "x",
          font: {
            fontWeight: "600",
            color: "#ffffff",
          },
        },
      },
      width: "100%",

      tooltip: {
        enable: true,
        format: "${point.x} : ${point.y}",
      },
      legend: {
        visible: false,
        enable: false,
      },
    };
  },
  computed: {
    ...mapGetters(["invoicesList"]),
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
    },
  },
  async mounted() {
    this.dataLoaded = true;
  },
  methods: {
    formatNumber,
    formatCurrency,
  },
  provide: {
    chart: [BarSeries, Legend, Category, Tooltip, DataLabel, Highlight],
  },
};
</script>
