<template lang="pug">
.h-100(v-if="dataLoaded") 
    ejs-accumulationchart(:ref="name" :title='title' height='100%' style='display:block;' :tooltip='tooltip' :legendSettings='legendSettings' :enableAnimation='false')
        e-accumulation-series-collection
            e-accumulation-series(:dataSource='data' xName='x' yName='y' :neckWidth='neckWidth' :neckHeight='neckHeight' :width='width' height='80%' :explode='explode' :dataLabel='dataLabel' type='Funnel')
</template>

<script>
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  FunnelSeries,
  AccumulationLegend,
  AccumulationDataLabel
} from "@syncfusion/ej2-vue-charts";
import { Browser } from '@syncfusion/ej2-base';

export default {
    components: {
        'ejs-accumulationchart': AccumulationChartComponent,
        'e-accumulation-series-collection': AccumulationSeriesCollectionDirective,
        'e-accumulation-series': AccumulationSeriesDirective
    },
    provide: {
        accumulationchart: [AccumulationLegend, FunnelSeries, AccumulationDataLabel, AccumulationTooltip ]
    },
    props:{
        data:{
            type:Array,
            default: () => []
        },
        viewMode:{
            type:String,
            default:"compare"
        }
    },
    data() {
        return {
            title: "",
            name: "funnelActivitiesToSales",
            dataLoaded:false,
            dataSource: [
                { InterviewProcess : "Hired", Candidates : 50, DataLabelMappingName:"Hired: 50"},
                { InterviewProcess : "Personal Interview", Candidates : 58, DataLabelMappingName: Browser.isDevice ? "Personal <br> Interview: 58" : "Personal Interview: 58"},
                { InterviewProcess : "Telephonic Interview", Candidates : 85, DataLabelMappingName:"Telephonic <br> Interview: 85"},
                { InterviewProcess : "Screening", Candidates : 105, DataLabelMappingName:"Screening: 105"},
                { InterviewProcess : "Initial Validation", Candidates : 145, DataLabelMappingName: Browser.isDevice ? "Initial <br> Validation: 145" :  "Initial Validation: 145"},
                { InterviewProcess : "Candidates Applied", Candidates : 250, DataLabelMappingName:"Candidates Applied: 250"},
            ],
            
            dataLabel: {
                name: 'text', visible: true, position: 'Inside', 
                font: {
                    fontWeight: '600',  
                },
                connectorStyle: {length:'20px'},
            },
            neckWidth: "15%",
            neckHeight: "18%",
            width: Browser.isDevice ? "100%" : "80%",
            explode: false,
            
            legendSettings: {
                visible: false
            },

            tooltip: { enable: false},
        }
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
    updated(){
        this.$nextTick(function() {
            this.$refs.funnelActivitiesToSales.ej2Instances.refresh();
            this.$refs.funnelActivitiesToSales.ej2Instances.removeSvg();
            this.$refs.funnelActivitiesToSales.ej2Instances.refreshSeries();
            this.$refs.funnelActivitiesToSales.ej2Instances.refreshChart();
        });
    }
};
</script>