<template lang="pug">
.h-100(v-if="dataLoaded") 
    ejs-accumulationchart(:ref="name" :title='title' :width="height+'px'" :height="height+'px'"  :theme="'Bootstrap5'" :legendSettings='legendSettings' :tooltip='tooltip' :enableSmartLables='enableSmartLables' :enableAnimation='enableAnimation' :center='center')
        e-accumulation-series-collection
            e-accumulation-series(:dataSource='data' :palettes='palettes' :startAngle='startAngle' :endAngle='endAngle' :radius='radius' xName='x' yName='y' :dataLabel='dataLabel' innerRadius='0%')  
</template>

<script>

import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  AccumulationSelection,
  Selection,
  ChartAnnotation
} from "@syncfusion/ej2-vue-charts";

export default {
    components: {
        'ejs-accumulationchart': AccumulationChartComponent,
        'e-accumulation-series-collection': AccumulationSeriesCollectionDirective,
        'e-accumulation-series': AccumulationSeriesDirective
    },
    provide: {
        accumulationchart: [AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation]
    },
    props:{
        data:{
            type:Array,
            default: () => []
        },
        name:{
            type:String,
            default:"pieChartStandard",
        },
        height:{
            type:Number,
            default:100,
        },
        viewMode:{
            type:String,
            default:"compare"
        },
        palettes:{
            type:Array,
            default: () => ["#EBEA00", "#F8F0A2", "#FCF5C4", "#FFFAE6"]
        },
    },
    data() {
        return {
            title: "",
            dataLoaded:false,
            dataLabel: {
                visible: true,
                position: 'Inside', name: 'text',
                font: {
                    fontWeight: '500',
                    color: '#5e5873'
                }
            },
            enableSmartLables: true,
            enableAnimation: false,
            legendSettings: {
                visible: false,
            },
            tooltip: { enable: true, format: '${point.x} : <b>${point.y}</b>' }, 
            startAngle: '0',
            endAngle: '360',
            radius: '75%',
            center: {x: '50%', y: '50%'},
        }
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
};
</script>