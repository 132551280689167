<template lang="pug">
div#dashboard
      .d-flex.flex-column
          .content-header.d-flex.justify-content-between.align-items-center.p-1
              h2.content-header-title.m-0.pr-1.mr-1.d-flex
                  span.material-icons-outlined.cursor-pointer(@click="setHomePage()" :class="currentHomePage ? 'text-primary' : ''" style="color: #ccc;") home
                  span.d-block.d-md-none CRM
                  span.d-none.d-md-block Tableau de bord > CRM
              .content-header-search-filter.mr-1.d-none.d-md-block(style="flex: 1;")
                  .position-relative.w-100.d-flex.align-items-center
                      .d-none.d-lg-block.mr-1
                          DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="dashboardCRMFilters" placeholder="Filtrer par période")
                      .d-none.d-lg-block.content-header-actions.mr-1
                          .e-btn-group.e-lib.e-css(role='group') 
                              input#viewDay(type='radio' name='viewSlot' value="day" v-model="viewSlot")
                              label.e-btn.e-outline(for='viewDay') Jour
                              input#viewMonth(type='radio' name='viewSlot' value="month" v-model="viewSlot")
                              label.e-btn.e-outline.border-primary(for='viewMonth') Mois
                      ejs-multiselect(cssClass="d-none d-lg-block e-fixe-height" id='collaboratorsList' name="Collaborateurs" placeholder='Filtrer par collaborateur' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="collaboratorsListFilteredByUseCRM" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterCollaborators' @change="onChangeFilterCollaborators")
              .content-header-search.d-block.d-md-none
              .content-header-actions.mr-1
                  .e-btn-group.e-lib.e-css(role='group') 
                      input#viewTypeQte(type='radio' name='viewType' value="sum" v-model="viewType")
                      label.e-btn.e-outline(for='viewTypeQte') Qté
                      input#viewTypeAmount(type='radio' name='viewType' value="amount" v-model="viewType")
                      label.e-btn.e-outline.border-primary(for='viewTypeAmount') Montant
              .content-header-actions(v-if="checkModule(6)")
                  .e-btn-group.e-lib.e-css.mr-1(role='group') 
                      input#viewCounted(type='radio' name='viewMode' value="counted" v-model="viewMode")
                      label.e-btn.e-outline(for='viewCounted') Réél
                      input#viewCompare(type='radio' name='viewMode' value="compare" v-model="viewMode" v-if="checkModule(6)")
                      label.e-btn.e-outline.border-primary(for='viewCompare' v-if="checkModule(6)") Réél vs Prévu
                      input#viewForecast(type='radio' name='viewMode' value="forecast" v-model="viewMode" v-if="checkModule(6)")
                      label.e-btn.e-outline(for='viewForecast' v-if="checkModule(6)") Prévu
                  ejs-dropdownbutton( cssClass="e-outline" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings"  :isPrimary="true" )
          b-col(cols='12').d-xl-none 
              .w-100.mb-50
                  DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="dashboardCRMFilters" placeholder="Filtrer par période")
              ejs-multiselect(cssClass="e-fixe-height" id='collaboratorsList' name="Collaborateurs" placeholder='Filtrer par collaborateur' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="collaboratorsListFilteredByUseCRM" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterCollaborators' @change="onChangeFilterCollaborators")
      .w-100 
          b-row.m-0.control-section.dashboard-default
              b-col(md="12" style='padding-top: 0px !important;')
                  .dashboard-default(v-if="dashboardLoaded")
                      ejs-dashboardlayout#defaultLayout(ref="DashbordInstance" :key="keyRefresh" :cellSpacing='spacing' :cellAspectRatio='aspectRatio' :columns='12' :allowFloating="true" :allowResizing="editDashboard" :allowDragging="editDashboard"  :mediaQuery="'max-width:991px'" @resizeStop="onChangeResizeStop" @resizeStart="onChangeResizeStart" @created="onCreatedDashboard" @change="onChangeResizeStop")
                          .e-panel(v-for="(panel, indexPanel) of panels" :key="indexPanel" :class="[editDashboard ? 'active' : '', panel.id=='activities-by-collaborators' ? 'card-list-stats-dashboard bg-white border-yellow p-0' : panel.id=='invoices-sent' ? 'card-list-stats-dashboard bg-white border-primary p-0' : panel.id=='quotes-sent-by-collaborators' || panel.id=='quotes-signed-by-collaborators' ? 'card-list-stats-dashboard bg-white border-turquoise p-0' : (panel.id=='order-forms-customers-sent-by-collaborators' || panel.id=='invoices-sent-by-collaborators' ) ? 'card-list-stats-dashboard bg-white border-primary p-0' : panel.id=='payments' ? 'card-list-stats-dashboard bg-white border-success p-0' : panel.id=='remainin-to-pay' ? 'card-list-stats-dashboard bg-white border-danger p-0' : '']" :id="panel.id" :data-row='panel.row' :data-col='panel.col' :data-sizeX='panel.sizeX' :data-sizeY='panel.sizeY' :data-minSizeX='panel.minSizeX' :data-minSizeY='panel.minSizeY')
                              .e-panel-container.p-1(v-if="panel.id=='activities-by-collaborators'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100
                                          h4.mb-0 Contacts utiles 
                                          div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                              //- b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h0.text-primary {{ viewMode=='forecast' ? countActivitiesGoals : countActivities }} 
                                                  small(v-if="viewMode=='compare'") / {{ countActivitiesGoals }}
                                      div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:percentActivitiesGoals}") 
                              .e-panel-container.p-1(v-if="panel.id=='quotes-sent-by-collaborators'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100
                                          h4.mb-0 Devis validés
                                          div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                              //- b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h0.text-primary {{ viewMode=='forecast' ? ( viewType=='sum' ? countQuotesSentGoals : formatCurrency(amountQuotesSentGoals) ) :  ( viewType=='sum' ? countQuotesSent :  formatCurrency(amountQuotesSent) ) }} 
                                                  small(v-if="viewMode=='compare' && viewType=='sum'") / {{ viewType=='sum' ? countQuotesSentGoals : formatCurrency(amountQuotesSentGoals) }}
                                      div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:viewType=='sum' ? percentQuotesSentGoals : percentAmountQuotesSentGoals}") 
                              .e-panel-container.p-1(v-if="panel.id=='quotes-signed-by-collaborators'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100
                                          h4.mb-0 Devis signés
                                          div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                              //- b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h0.text-primary {{ viewMode=='forecast' ? ( viewType=='sum' ? countQuotesSignedGoals : formatCurrency(amountQuotesSignedGoals) ) :  ( viewType=='sum' ? countQuotesSigned : formatCurrency(amountQuotesSigned) ) }} 
                                                  small(v-if="viewMode=='compare' && viewType=='sum'") / {{ viewType=='sum' ? countQuotesSignedGoals : formatCurrency(amountQuotesSignedGoals) }} 
                                      div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:viewType=='sum' ? percentQuotesSignedGoals : percentAmountQuotesSignedGoals}") 
                              .e-panel-container.p-1(v-if="panel.id=='order-forms-customers-sent-by-collaborators'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100
                                          h4.mb-0 Commandes validées
                                          div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                              //- b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h0.text-primary {{ viewMode=='forecast' ? ( viewType=='sum' ? countOrderFormsCustomersSentGoals : formatCurrency(amountOrderFormsCustomersSentGoals) ) :  ( viewType=='sum' ? countOrderFormsCustomersSent : formatCurrency(amountOrderFormsCustomersSent) ) }} 
                                                  small(v-if="viewMode=='compare' && viewType=='sum'") / {{ viewType=='sum' ? countOrderFormsCustomersSentGoals : formatCurrency(amountOrderFormsCustomersSentGoals) }} 
                                      div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:viewType=='sum' ? percentOrderFormsCustomersSentGoals : percentAmountOrderFormsCustomersSentGoals}") 
                              .e-panel-container.p-1(v-if="panel.id=='invoices-sent-by-collaborators'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100
                                          h4.mb-0 Factures émises
                                          div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                              //- b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h0.text-primary {{ viewMode=='forecast' ? ( viewType=='sum' ? countInvoicesSentGoals : formatCurrency(amountInvoicesSentGoals) ) :  ( viewType=='sum' ? countInvoicesSent : formatCurrency(amountInvoicesSent) ) }} 
                                                  small(v-if="viewMode=='compare' && viewType=='sum'") / {{ viewType=='sum' ? countInvoicesSentGoals : formatCurrency(amountInvoicesSentGoals) }} 
                                      div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:viewType=='sum' ? percentOrderFormsCustomersSentGoals : percentAmountOrderFormsCustomersSentGoals}") 
                              .e-panel-container.p-1(v-else-if="panel.id=='global-chart-CRM'")  
                                  h4.mb-0.position-absolute Statistiques par collaborateur
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                      b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                  global-chart-CRM(v-else-if="panel.loaded" :viewType="viewType" :viewMode="viewMode" :data="dataGlobal")
                              .e-panel-container.p-1(v-else-if="panel.id=='ranking-goals-by-collaborators'")  
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  h4.mb-0 Classement par collaborateur
                                  div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                      b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                  chartRankingGoalsByCollaborators(v-else-if="panel.loaded" :height="panel.height" :name="panel.id" :data="dataCollaboratorsFiltered" :viewType="viewType" :viewMode="viewMode" )
                              .e-panel-container.p-1(v-else-if="panel.id=='pie-chart-activities-by-source-types'")
                                  h4.mb-0.position-absolute Contacts par origine
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                      b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                  pie-chart-standard(:ref="'pie-chart-'+panel.id" :height="panel.height" :name="panel.id" v-else-if="dataLoaded && panel.loaded" :data="dataActivitiesBySourceTypesFiltered")  
                              .e-panel-container.p-1(v-else-if="panel.id=='pie-chart-activities-by-chanel-types'")
                                  h4.mb-0.position-absolute Contacts par canal de communication
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                      b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                  pie-chart-standard(:ref="'pie-chart-'+panel.id" :height="panel.height" :name="panel.id" v-else-if="dataLoaded && panel.loaded" :data="dataActivitiesByChanelTypesFiltered")  
                              .e-panel-container.p-0(v-if="panel.id=='global-grid-CRM'")
                                  .d-flex.flex-column.justify-content-between.h-100.w-100
                                      h4.p-1.mb-0.position-absolute(style="z-index:2; padding-top: 10px !important;") Suivi par collaborateur
                                      span.p-1.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                      div.text-center.flex-center.h-100(v-if="!dataLoaded" style="flex: 1;")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      globalGridCRM(v-else-if="dataLoaded && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :viewSlot="viewSlot" :data="dataCollaboratorsFiltered" :dateRange="dateRange" style="flex: 1;")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-vue-layouts";

import {
  formatNumber,
  formatCurrency,
  replaceNbsps,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  quoteStatus,
  orderFormCustomerStatus,
  invoiceStatus,
  orderFormProviderStatus,
  invoiceProviderStatus,
} from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";

import GlobalChartCRM from "@/components/dashboard/crm/GlobalChartCRM";
import PieChartActivitiesByCollaborators from "@/components/dashboard/crm/PieChartActivitiesByCollaborators";
import PieChartActivitiesByChanelTypes from "@/components/dashboard/crm/PieChartActivitiesByChanelTypes";
import FunnelActivitiesToSales from "@/components/dashboard/crm/FunnelActivitiesToSales";
import CircularGaugePercent from "@/components/dashboard/crm/CircularGaugePercent";
import PieChartStandard from "@/components/dashboard/crm/PieChartStandard";
import ChartRankingGoalsByCollaborators from "@/components/dashboard/crm/ChartRankingGoalsByCollaborators";
import GlobalGridCRM from "@/components/dashboard/crm/GlobalGridCRM";

export default {
  components: {
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    DateRanger,
    GlobalChartCRM,
    PieChartActivitiesByCollaborators,
    PieChartActivitiesByChanelTypes,
    FunnelActivitiesToSales,
    CircularGaugePercent,
    PieChartStandard,
    ChartRankingGoalsByCollaborators,
    GlobalGridCRM,
  },
  data() {
    return {
      currentHomePage: false,
      title: "Tableau de bord de gestion",
      name: "dashboardInvoice",
      tabActive: "invoices",
      status: 0,
      tagsSelected: [],
      selectedRows: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      dateRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      filterAffairs: [],
      filterCollaborators: [],
      filterCollaboratorsUserIds: [],
      tvaMode: "HT",

      dataLoaded: false,
      statusList: [],
      keyRefresh: 0,

      viewMode: "compare",
      viewType: "amount",
      viewSlot: "month",

      dashboardLoaded: false,
      editDashboard: false,
      aspectRatio: 100 / 80,
      spacing: [10, 10],
      panelsDefault: [
        {
          id: "activities-by-collaborators",
          row: 0,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "quotes-sent-by-collaborators",
          row: 1,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "quotes-signed-by-collaborators",
          row: 2,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "order-forms-customers-sent-by-collaborators",
          row: 3,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "invoices-sent-by-collaborators",
          row: 4,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "global-chart-CRM",
          row: 0,
          col: 3,
          sizeX: 9,
          sizeY: 5,
        },
        {
          id: "global-grid-CRM",
          row: 5,
          col: 0,
          sizeX: 12,
          sizeY: 6,
        },
        {
          id: "ranking-goals-by-collaborators",
          row: 11,
          col: 0,
          sizeX: 6,
          sizeY: 4,
        },
        {
          id: "pie-chart-activities-by-source-types",
          row: 11,
          col: 6,
          sizeX: 3,
          sizeY: 4,
        },
        {
          id: "pie-chart-activities-by-chanel-types",
          row: 11,
          col: 9,
          sizeX: 3,
          sizeY: 4,
        },
      ],
      panels: [],
      parametersActionList: [
        {
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        },
        {
          text: "Réinitiliser mon tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-reset",
          type: "dashboardCRM",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "activitiesList",
      "invoicesList",
      "quotesList",
      "orderFormCustomersList",
      "collaboratorsList",
      "isLoadingCollaboratorsList",
      "affairsList",
      "isLoadingQuotesList",
      "isLoadingInvoicesList",
      "workspaceSelected",
      "userParametersList",
      "userCollaboratorId",
      "userCollaborator",
    ]),
    collaboratorsListFilteredByUseCRM() {
      return this.collaboratorsList.filter(
        (collab) => collab.useCrm === true && collab.userId
      );
    },
    statisticsDurationInYears() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "years",
        true
      );
    },
    statisticsDurationInMonths() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "months",
        true
      );
    },
    statisticsDurationInDays() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "days",
        true
      );
    },
    countWorkDays() {
      const start = new Date(this.dateRange.startDate);
      const end = new Date(this.dateRange.endDate);

      // Nombre de jours ouvrés
      let workingDays = 0;

      // Boucle à travers chaque jour entre les dates
      while (start <= end) {
        // Vérifier si le jour actuel n'est pas un week-end (samedi ou dimanche)
        if (start.getDay() !== 0 && start.getDay() !== 6) {
          workingDays++;
        }

        // Passage au jour suivant
        start.setDate(start.getDate() + 1);
      }
      return workingDays;
    },

    dataGlobal() {
      let dataGlobalMapped = [];

      let dateDebut = new Date(this.dateRange.startDate);
      let dateFin = new Date(this.dateRange.endDate);
      let dateActuelle = new Date(dateDebut);

      while (dateActuelle <= dateFin) {
        let dateStr = dayjs(dateActuelle).startOf("month").format("MMM YY");
        let totalActivities = 0;
        totalActivities = this.dataActivitiesFiltered.filter((activities) =>
          this.viewSlot == "month"
            ? dayjs(dateActuelle).format("YYYY-MM") ==
              dayjs(activities.date).format("YYYY-MM")
            : dayjs(dateActuelle).format("YYYY-MM-DD") ==
              dayjs(activities.date).format("YYYY-MM-DD")
        ).length;

        let totalQuotesSent = 0;
        let amountQuotesSent = 0;
        this.dataQuotesFiltered
          .filter(
            (quote) =>
              [9, 3, 2].includes(quote.status) &&
              (this.viewSlot == "month"
                ? dayjs(dateActuelle).format("YYYY-MM") ==
                  dayjs(quote.documentDate).format("YYYY-MM")
                : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                  dayjs(quote.documentDate).format("YYYY-MM-DD"))
          )
          .forEach((quotes) => {
            totalQuotesSent++;
            amountQuotesSent += quotes.totalHt;
          });
        let totalQuotesSigned = 0;
        let amountQuotesSigned = 0;
        this.dataQuotesFiltered
          .filter(
            (quote) =>
              [3].includes(quote.status) &&
              (this.viewSlot == "month"
                ? dayjs(dateActuelle).format("YYYY-MM") ==
                  dayjs(quote.documentDate).format("YYYY-MM")
                : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                  dayjs(quote.documentDate).format("YYYY-MM-DD"))
          )
          .forEach((quotes) => {
            totalQuotesSigned++;
            amountQuotesSigned += quotes.totalHt;
          });
        let amountOrderFormsCustomersSent = 0;
        let totalOrderFormsCustomersSent = 0;
        this.dataOrderFormsCustomersFiltered
          .filter(
            (orderFormCustomer) =>
              [3, 4, 7].includes(orderFormCustomer.status) &&
              (this.viewSlot == "month"
                ? dayjs(dateActuelle).format("YYYY-MM") ==
                  dayjs(orderFormCustomer.documentDate).format("YYYY-MM")
                : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                  dayjs(orderFormCustomer.documentDate).format("YYYY-MM-DD"))
          )
          .forEach((orderFormCustomers) => {
            totalOrderFormsCustomersSent++;
            amountOrderFormsCustomersSent += orderFormCustomers.totalHt;
          });
        let amountInvoicesSent = 0;
        let totalInvoicesSent = 0;
        this.dataInvoicesFiltered
          .filter(
            (invoice) =>
              [2, 6, 7, 9, 10].includes(invoice.status) &&
              (this.viewSlot == "month"
                ? dayjs(dateActuelle).format("YYYY-MM") ==
                  dayjs(invoice.documentDate).format("YYYY-MM")
                : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                  dayjs(invoice.documentDate).format("YYYY-MM-DD"))
          )
          .forEach((invoices) => {
            totalInvoicesSent++;
            amountInvoicesSent += invoices.totalHt;
          });

        dataGlobalMapped.push({
          date:
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD"),
          dateStr: dateStr.toUpperCase(),
          totalActivities: totalActivities,
          totalActivitiesDisplayText:
            totalActivities > 1
              ? totalActivities + " contacts utiles"
              : totalActivities + " contact utile",
          colorActivities: "#BFD0FF",
          totalQuotesSent: totalQuotesSent,
          totalQuotesSentDisplayText:
            totalQuotesSent > 1
              ? totalQuotesSent + " devis validés"
              : totalQuotesSent + " devis validé",
          colorQuotesSent: "#6A7EC3",
          totalQuotesSigned: totalQuotesSigned,
          totalQuotesSignedDisplayText:
            totalQuotesSigned > 1
              ? totalQuotesSigned + " devis signés"
              : totalQuotesSigned + " devis signés",
          colorQuotesSigned: "#6A7EC3",
          totalInvoicesSent: totalInvoicesSent,
          totalInvoicesSentDisplayText:
            totalInvoicesSent > 1
              ? totalInvoicesSent + " factures émises"
              : totalInvoicesSent + " facture émise",
          colorInvoicesSent: "#6A7EC3",
          totalOrderFormsCustomersSent: totalOrderFormsCustomersSent,
          totalOrderFormsCustomersSentDisplayText:
            totalOrderFormsCustomersSent > 1
              ? totalOrderFormsCustomersSent + " commandes validées"
              : totalInvoicesSent + " commande validée",
          colorOrderFormsCustomersSent: "#6A7EC3",
          amountQuotesSent: amountQuotesSent.toFixed(2),
          amountQuotesSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountQuotesSent.toFixed(2))
          ),
          amountQuotesSigned: amountQuotesSigned.toFixed(2),
          amountQuotesSignedDisplayText: this.replaceNbsps(
            this.formatCurrency(amountQuotesSigned.toFixed(2))
          ),
          amountInvoicesSent: amountInvoicesSent.toFixed(2),
          amountInvoicesSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountInvoicesSent.toFixed(2))
          ),
          amountOrderFormsCustomersSent:
            amountOrderFormsCustomersSent.toFixed(2),
          amountOrderFormsCustomersSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountOrderFormsCustomersSent.toFixed(2))
          ),
        });

        if (this.viewSlot == "month") {
          if (dateActuelle.getMonth() === 11) {
            dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
            dateActuelle.setMonth(0);
          } else {
            dateActuelle.setMonth(dateActuelle.getMonth() + 1);
          }
        } else {
          dateActuelle.setDate(dateActuelle.getDate() + 1);
        }
      }
      return dataGlobalMapped;
    },

    dataCollaborators() {
      return this.collaboratorsListFilteredByUseCRM.map((el) => {
        let totalActivities = this.activitiesList.filter(
          (activity) =>
            activity.activityResultType.label.toUpperCase() !== "AUCUN" &&
            activity.collaboratorId == el.id
        ).length;
        let amountOrderFormsCustomersSent = 0;
        let totalOrderFormsCustomersSent = 0;
        this.orderFormCustomersList
          .filter(
            (orderFormCustomer) =>
              [3, 4, 7].includes(orderFormCustomer.status) &&
              orderFormCustomer.userId == el.userId
          )
          .forEach((orderFormCustomers) => {
            totalOrderFormsCustomersSent++;
            amountOrderFormsCustomersSent += orderFormCustomers.totalHt;
          });
        let amountInvoicesSent = 0;
        let totalInvoicesSent = 0;
        this.invoicesList
          .filter(
            (invoice) =>
              [2, 6, 7, 9, 10].includes(invoice.status) &&
              invoice.userId == el.userId
          )
          .forEach((invoices) => {
            totalInvoicesSent++;
            amountInvoicesSent += invoices.totalHt;
          });
        let totalQuotesSent = 0;
        let amountQuotesSent = 0;
        this.quotesList
          .filter(
            (quote) =>
              [2, 3, 9, 10].includes(quote.status) && quote.userId == el.userId
          )
          .forEach((quotes) => {
            totalQuotesSent++;
            amountQuotesSent += quotes.totalHt;
          });
        let totalQuotesSigned = 0;
        let amountQuotesSigned = 0;
        this.quotesList
          .filter(
            (quote) => [3].includes(quote.status) && quote.userId == el.userId
          )
          .forEach((quotes) => {
            totalQuotesSigned++;
            amountQuotesSigned += quotes.totalHt;
          });

        let statisticsPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.statisticsPeriod
          : 1;
        let numbersActivitiesByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.numbersActivitiesByPeriod
          : 0;
        let numbersActivitiesByMonth = 0;
        let numbersQuotesSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.numbersQuotesSentByPeriod
          : 0;
        let numbersQuotesSignedByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.numbersQuotesSignedByPeriod
          : 0;
        let numbersInvoicesSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.numbersInvoicesSentByPeriod
          : 0;
        let numbersOrderFormsCustomersSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.numbersOrderFormsCustomersSentByPeriod
          : 0;
        let amountQuotesSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.amountQuotesSentByPeriod
          : 0;
        let amountQuotesSignedByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.amountQuotesSignedByPeriod
          : 0;
        let amountInvoicesSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.amountInvoicesSentByPeriod
          : 0;
        let amountOrderFormsCustomersSentByPeriod = el.collaboratorGoal
          ? el.collaboratorGoal.amountOrderFormsCustomersSentByPeriod
          : 0;
        let amountOrderFormsCustomersSentByMonth = 0;

        // On génère les colonnes des mois
        let dateDebut = new Date(this.dateRange.startDate);
        let dateFin = new Date(this.dateRange.endDate);
        let dateActuelle = new Date(dateDebut);
        let monthsDetails = {};
        while (dateActuelle <= dateFin) {
          let dateStr = dayjs(dateActuelle).startOf("month").format("MM_YYYY");
          monthsDetails[dateStr + "_activity"] = 0;
          monthsDetails[dateStr + "_activityGoals"] = 0;
          monthsDetails[dateStr + "_quotesSentNumbers"] = 0;
          monthsDetails[dateStr + "_quotesSentAmount"] = 0;
          monthsDetails[dateStr + "_quotesSignedNumbers"] = 0;
          monthsDetails[dateStr + "_quotesSignedAmount"] = 0;
          monthsDetails[dateStr + "_invoicesSentNumbers"] = 0;
          monthsDetails[dateStr + "_invoicesSentAmount"] = 0;
          monthsDetails[dateStr + "_orderFormsCustomersSentNumbers"] = 0;
          monthsDetails[dateStr + "_orderFormsCustomersSentAmount"] = 0;

          monthsDetails[dateStr + "_activity"] = this.activitiesList.filter(
            (activity) =>
              activity.activityResultType.label.toUpperCase() !== "AUCUN" &&
              activity.collaboratorId == el.id &&
              dayjs(activity.activityDate).format("MM_YYYY") == dateStr
          ).length;
          this.quotesList
            .filter(
              (quote) =>
                [2, 3, 9, 10].includes(quote.status) &&
                quote.userId == el.userId &&
                dayjs(quote.documentDate).format("MM_YYYY") == dateStr
            )
            .forEach((quotes) => {
              monthsDetails[dateStr + "_quotesSentNumbers"]++;
              monthsDetails[dateStr + "_quotesSentAmount"] += quotes.totalHt;
            });
          this.quotesList
            .filter(
              (quote) =>
                [3].includes(quote.status) &&
                quote.userId == el.userId &&
                dayjs(quote.documentDate).format("MM_YYYY") == dateStr
            )
            .forEach((quotes) => {
              monthsDetails[dateStr + "_quotesSignedNumbers"]++;
              monthsDetails[dateStr + "_quotesSignedAmount"] += quotes.totalHt;
            });
          this.invoicesList
            .filter(
              (invoice) =>
                [2, 6, 7, 9, 10].includes(invoice.status) &&
                invoice.userId == el.userId &&
                dayjs(invoice.documentDate).format("MM_YYYY") == dateStr
            )
            .forEach((invoices) => {
              monthsDetails[dateStr + "_invoicesSentNumbers"]++;
              monthsDetails[dateStr + "_invoicesSentAmount"] +=
                invoices.totalHt;
            });
          this.orderFormCustomersList
            .filter(
              (orderFormCustomer) =>
                [3, 4, 7].includes(orderFormCustomer.status) &&
                orderFormCustomer.userId == el.userId &&
                dayjs(orderFormCustomer.documentDate).format("MM_YYYY") ==
                  dateStr
            )
            .forEach((orderFormCustomers) => {
              monthsDetails[dateStr + "_orderFormsCustomersSentNumbers"]++;
              monthsDetails[dateStr + "_orderFormsCustomersSentAmount"] +=
                orderFormCustomers.totalHt;
            });

          if (dateActuelle.getMonth() === 11) {
            dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
            dateActuelle.setMonth(0);
          } else {
            dateActuelle.setMonth(dateActuelle.getMonth() + 1);
          }
        }

        if (statisticsPeriod == 0) {
          // year
          numbersActivitiesByMonth = Math.round(numbersActivitiesByPeriod / 12);
          numbersActivitiesByPeriod = Math.round(
            numbersActivitiesByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          numbersQuotesSentByPeriod = Math.round(
            numbersQuotesSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          numbersQuotesSignedByPeriod = Math.round(
            numbersQuotesSignedByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          numbersInvoicesSentByPeriod = Math.round(
            numbersInvoicesSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          numbersOrderFormsCustomersSentByPeriod = Math.round(
            numbersOrderFormsCustomersSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          amountQuotesSentByPeriod = Math.round(
            amountQuotesSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          amountQuotesSignedByPeriod = Math.round(
            amountQuotesSignedByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          amountInvoicesSentByPeriod = Math.round(
            amountInvoicesSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          amountOrderFormsCustomersSentByPeriod = Math.round(
            amountOrderFormsCustomersSentByPeriod *
              Math.round(this.statisticsDurationInYears)
          );
          amountOrderFormsCustomersSentByMonth = Math.round(
            amountOrderFormsCustomersSentByPeriod / 12
          );
        } else if (statisticsPeriod == 1) {
          // quarter
          numbersActivitiesByMonth = Math.round(numbersActivitiesByPeriod / 3);
          numbersActivitiesByPeriod = Math.round(
            numbersActivitiesByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          numbersQuotesSentByPeriod = Math.round(
            numbersQuotesSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          numbersQuotesSignedByPeriod = Math.round(
            numbersQuotesSignedByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          numbersInvoicesSentByPeriod = Math.round(
            numbersInvoicesSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          numbersOrderFormsCustomersSentByPeriod = Math.round(
            numbersOrderFormsCustomersSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          amountQuotesSentByPeriod = Math.round(
            amountQuotesSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          amountQuotesSignedByPeriod = Math.round(
            amountQuotesSignedByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          amountInvoicesSentByPeriod = Math.round(
            amountInvoicesSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          amountOrderFormsCustomersSentByPeriod = Math.round(
            amountOrderFormsCustomersSentByPeriod *
              Math.round(this.statisticsDurationInMonths / 3)
          );
          amountOrderFormsCustomersSentByMonth = Math.round(
            amountOrderFormsCustomersSentByPeriod / 3
          );
        } else if (statisticsPeriod == 2) {
          // mounth
          numbersActivitiesByMonth = numbersActivitiesByPeriod;
          const statisticsDurationInMonthsRounded =
            Math.floor(this.statisticsDurationInMonths * Math.pow(10, 1)) /
            Math.pow(10, 1);

          numbersActivitiesByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? numbersActivitiesByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (numbersActivitiesByPeriod / 21.67) * this.countWorkDays
              );
          numbersQuotesSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? numbersQuotesSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (numbersQuotesSentByPeriod / 21.67) * this.countWorkDays
              );
          numbersQuotesSignedByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? numbersQuotesSignedByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (numbersQuotesSignedByPeriod / 21.67) * this.countWorkDays
              );
          numbersInvoicesSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? numbersInvoicesSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (numbersInvoicesSentByPeriod / 21.67) * this.countWorkDays
              );
          numbersOrderFormsCustomersSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? numbersOrderFormsCustomersSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (numbersOrderFormsCustomersSentByPeriod / 21.67) *
                  this.countWorkDays
              );
          amountQuotesSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? amountQuotesSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (amountQuotesSentByPeriod / 21.67) * this.countWorkDays
              );
          amountQuotesSignedByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? amountQuotesSignedByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (amountQuotesSignedByPeriod / 21.67) * this.countWorkDays
              );
          amountInvoicesSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? amountInvoicesSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (amountInvoicesSentByPeriod / 21.67) * this.countWorkDays
              );

          amountOrderFormsCustomersSentByMonth = Math.round(
            amountOrderFormsCustomersSentByPeriod
          );
          amountOrderFormsCustomersSentByPeriod = ["0", "9"].includes(
            statisticsDurationInMonthsRounded.toFixed(1).split(".")[1]
          )
            ? amountOrderFormsCustomersSentByPeriod *
              Math.ceil(this.statisticsDurationInMonths)
            : Math.round(
                (amountOrderFormsCustomersSentByPeriod / 21.67) *
                  this.countWorkDays
              );
        } else {
          numbersActivitiesByMonth = Math.round(
            numbersActivitiesByPeriod * 21.67
          );
          // jour
          numbersActivitiesByPeriod = Math.round(
            numbersActivitiesByPeriod * this.countWorkDays
          );
          numbersQuotesSentByPeriod = Math.round(
            numbersQuotesSentByPeriod * this.countWorkDays
          );
          numbersQuotesSignedByPeriod = Math.round(
            numbersQuotesSignedByPeriod * this.countWorkDays
          );
          numbersInvoicesSentByPeriod = Math.round(
            numbersInvoicesSentByPeriod * this.countWorkDays
          );
          numbersOrderFormsCustomersSentByPeriod = Math.round(
            numbersOrderFormsCustomersSentByPeriod * this.countWorkDays
          );
          amountQuotesSentByPeriod = Math.round(
            amountQuotesSentByPeriod * this.countWorkDays
          );
          amountQuotesSignedByPeriod = Math.round(
            amountQuotesSignedByPeriod * this.countWorkDays
          );
          amountInvoicesSentByPeriod = Math.round(
            amountInvoicesSentByPeriod * this.countWorkDays
          );
          amountOrderFormsCustomersSentByPeriod = Math.round(
            amountOrderFormsCustomersSentByPeriod * this.countWorkDays
          );
          amountOrderFormsCustomersSentByMonth = Math.round(
            amountOrderFormsCustomersSentByPeriod * this.countWorkDays
          );
        }
        return {
          collaboratorId: el.id,
          collaboratorName: el.displayLabel,
          job: el.job.label,
          displayText: "",
          totalActivities: totalActivities,
          colorActivities: "#BFD0FF",
          totalQuotesSent: totalQuotesSent,
          totalQuotesSigned: totalQuotesSigned,
          totalInvoicesSent: totalInvoicesSent,
          totalOrderFormsCustomersSent: totalOrderFormsCustomersSent,
          amountQuotesSent: amountQuotesSent,
          amountQuotesSigned: amountQuotesSigned,
          amountInvoicesSent: amountInvoicesSent,
          amountOrderFormsCustomersSent: amountOrderFormsCustomersSent,

          numbersActivitiesByPeriod: numbersActivitiesByPeriod,
          numbersActivitiesByMonth: numbersActivitiesByMonth,
          numbersQuotesSentByPeriod: numbersQuotesSentByPeriod,
          numbersQuotesSignedByPeriod: numbersQuotesSignedByPeriod,
          numbersInvoicesSentByPeriod: numbersInvoicesSentByPeriod,
          numbersOrderFormsCustomersSentByPeriod:
            numbersOrderFormsCustomersSentByPeriod,
          amountQuotesSentByPeriod: amountQuotesSentByPeriod,
          amountQuotesSignedByPeriod: amountQuotesSignedByPeriod,
          amountInvoicesSentByPeriod: amountInvoicesSentByPeriod,
          amountOrderFormsCustomersSentByPeriod:
            amountOrderFormsCustomersSentByPeriod,
          amountOrderFormsCustomersSentByMonth:
            amountOrderFormsCustomersSentByMonth,
          statisticsPeriod: statisticsPeriod,
          ...monthsDetails,
        };
      });
    },
    dataCollaboratorsFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.dataCollaborators.filter((el) =>
          this.filterCollaborators.includes(el.collaboratorId)
        );
      } else {
        return this.dataCollaborators;
      }
    },

    dataActivitiesFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.activitiesList.filter(
          (activity) =>
            this.filterCollaborators.includes(activity.collaboratorId) &&
            activity.activityResultType.label.toUpperCase() !== "AUCUN"
        );
      } else {
        return this.activitiesList.filter(
          (activity) =>
            activity.activityResultType.label.toUpperCase() !== "AUCUN"
        );
      }
    },
    countActivities() {
      let sum = 0;
      // iterate over each item in the array
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].totalActivities;
      }
      return sum;
    },
    countActivitiesGoals() {
      let sum = 0;
      // iterate over each item in the array
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].numbersActivitiesByPeriod;
      }
      return sum;
    },
    percentActivitiesGoals() {
      if (this.countActivities == 0 && this.countActivitiesGoals == 0) {
        return 100;
      } else if (this.countActivitiesGoals == 0) {
        return 100;
      } else if (this.countActivities == 0) {
        return 0;
      } else {
        return Math.round(
          (this.countActivities / this.countActivitiesGoals) * 100
        );
      }
    },

    dataQuotesFiltered() {
      if (this.filterCollaboratorsUserIds.length > 0) {
        return this.quotesList.filter((quote) =>
          this.filterCollaboratorsUserIds.includes(quote.userId)
        );
      } else {
        return this.quotesList;
      }
    },
    countQuotesSent() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].totalQuotesSent;
      }
      return sum;
    },
    countQuotesSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].numbersQuotesSentByPeriod;
      }
      return sum;
    },
    percentQuotesSentGoals() {
      if (this.countQuotesSent == 0 && this.countQuotesSentGoals == 0) {
        return 100;
      } else if (this.countQuotesSentGoals == 0) {
        return 100;
      } else if (this.countQuotesSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.countQuotesSent / this.countQuotesSentGoals) * 100
        );
      }
    },

    countQuotesSigned() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].totalQuotesSigned;
      }
      return sum;
    },
    countQuotesSignedGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].numbersQuotesSignedByPeriod;
      }
      return sum;
    },
    percentQuotesSignedGoals() {
      if (this.countQuotesSigned == 0 && this.countQuotesSignedGoals == 0) {
        return 100;
      } else if (this.countQuotesSignedGoals == 0) {
        return 100;
      } else if (this.countQuotesSigned == 0) {
        return 0;
      } else {
        return Math.round(
          (this.countQuotesSigned / this.countQuotesSignedGoals) * 100
        );
      }
    },

    dataInvoicesFiltered() {
      if (this.filterCollaboratorsUserIds.length > 0) {
        return this.invoicesList.filter((quote) =>
          this.filterCollaboratorsUserIds.includes(quote.userId)
        );
      } else {
        return this.invoicesList;
      }
    },
    countInvoicesSent() {
      let sum = 0;
      if (this.filterCollaboratorsUserIds.length > 0) {
        for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
          sum += this.dataCollaboratorsFiltered[i].totalInvoicesSent;
        }
      } else {
        this.invoicesList
          .filter((invoice) => [2, 6, 7, 9, 10].includes(invoice.status))
          .forEach((invoices) => {
            sum++;
          });
      }
      return sum;
    },
    countInvoicesSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].numbersInvoicesSentByPeriod;
      }
      return sum;
    },
    percentInvoicesSentGoals() {
      if (this.countInvoicesSent == 0 && this.countInvoicesSentGoals == 0) {
        return 100;
      } else if (this.countInvoicesSentGoals == 0) {
        return 100;
      } else if (this.countInvoicesSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.countInvoicesSent / this.countInvoicesSentGoals) * 100
        );
      }
    },

    dataOrderFormsCustomersFiltered() {
      if (this.filterCollaboratorsUserIds.length > 0) {
        return this.orderFormCustomersList.filter((quote) =>
          this.filterCollaboratorsUserIds.includes(quote.userId)
        );
      } else {
        return this.orderFormCustomersList;
      }
    },
    countOrderFormsCustomersSent() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].totalOrderFormsCustomersSent;
      }
      return sum;
    },
    countOrderFormsCustomersSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum +=
          this.dataCollaboratorsFiltered[i]
            .numbersOrderFormsCustomersSentByPeriod;
      }
      return sum;
    },
    percentOrderFormsCustomersSentGoals() {
      if (
        this.countOrderFormsCustomersSent == 0 &&
        this.countOrderFormsCustomersSentGoals == 0
      ) {
        return 100;
      } else if (this.countOrderFormsCustomersSentGoals == 0) {
        return 100;
      } else if (this.countOrderFormsCustomersSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.countOrderFormsCustomersSent /
            this.countOrderFormsCustomersSentGoals) *
            100
        );
      }
    },

    amountOrderFormsCustomersSent() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountOrderFormsCustomersSent;
      }
      return sum;
    },
    amountOrderFormsCustomersSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum +=
          this.dataCollaboratorsFiltered[i]
            .amountOrderFormsCustomersSentByPeriod;
      }
      return sum;
    },
    percentAmountOrderFormsCustomersSentGoals() {
      if (
        this.amountOrderFormsCustomersSent == 0 &&
        this.amountOrderFormsCustomersSentGoals == 0
      ) {
        return 100;
      } else if (this.amountOrderFormsCustomersSentGoals == 0) {
        return 100;
      } else if (this.amountOrderFormsCustomersSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.amountOrderFormsCustomersSent /
            this.amountOrderFormsCustomersSentGoals) *
            100
        );
      }
    },

    amountInvoicesSent() {
      let sum = 0;
      if (this.filterCollaboratorsUserIds.length > 0) {
        for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
          sum += this.dataCollaboratorsFiltered[i].amountInvoicesSent;
        }
      } else {
        this.invoicesList
          .filter((invoice) => [2, 6, 7, 9, 10].includes(invoice.status))
          .forEach((invoices) => {
            sum += invoices.totalHt;
          });
      }
      return sum;
    },
    amountInvoicesSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountInvoicesSentByPeriod;
      }
      return sum;
    },
    percentAmountInvoicesSentGoals() {
      if (this.amountInvoicesSent == 0 && this.amountInvoicesSentGoals == 0) {
        return 100;
      } else if (this.amountInvoicesSentGoals == 0) {
        return 100;
      } else if (this.amountInvoicesSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.amountInvoicesSent / this.amountInvoicesSentGoals) * 100
        );
      }
    },

    amountQuotesSent() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountQuotesSent;
      }
      return sum;
    },
    amountQuotesSentGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountQuotesSentByPeriod
          ? this.dataCollaboratorsFiltered[i].amountQuotesSentByPeriod
          : 0;
      }
      return sum;
    },
    percentAmountQuotesSentGoals() {
      if (this.amountQuotesSent == 0 && this.amountQuotesSentGoals == 0) {
        return 100;
      } else if (this.amountQuotesSentGoals == 0) {
        return 100;
      } else if (this.amountQuotesSent == 0) {
        return 0;
      } else {
        return Math.round(
          (this.amountQuotesSent / this.amountQuotesSentGoals) * 100
        );
      }
    },

    amountQuotesSigned() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountQuotesSigned;
      }
      return sum;
    },
    amountQuotesSignedGoals() {
      let sum = 0;
      for (let i = 0; i < this.dataCollaboratorsFiltered.length; i++) {
        sum += this.dataCollaboratorsFiltered[i].amountQuotesSignedByPeriod;
      }
      return sum;
    },
    percentAmountQuotesSignedGoals() {
      if (this.amountQuotesSigned == 0 && this.amountQuotesSignedGoals == 0) {
        return 100;
      } else if (this.amountQuotesSignedGoals == 0) {
        return 100;
      } else if (this.amountQuotesSigned == 0) {
        return 0;
      } else {
        return Math.round(
          (this.amountQuotesSigned / this.amountQuotesSignedGoals) * 100
        );
      }
    },

    dataFunnelActivitiesToSales() {
      let dataFunnelActivitiesToSalesMapped = [];
      dataFunnelActivitiesToSalesMapped = this.activitiesList.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex(
            (el) => el.activityResultTypeId == obj.activityResultType.id
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              activityResultTypeId: obj.activityResultType.id,
              activityResultTypeName: obj.activityResultType.label,
              collaboratorId: obj.collaboratorId,
              collaboratorName: this.collaboratorsListFilteredByUseCRM.find(
                (el) => el.id == obj.collaboratorId
              )
                ? this.collaboratorsListFilteredByUseCRM.find(
                    (el) => el.id == obj.collaboratorId
                  ).displayLabel
                : "Collaborateur non identifié",
              displayText: obj.activityResultType.label + " : 1",
              totalActivities: 1,
            });
          } else {
            // On additionne
            acc[findIndex]["totalActivities"] += 1;
            acc[findIndex]["displayText"] =
              acc[findIndex]["activityResultTypeName"] +
              " : " +
              acc[findIndex]["totalActivities"];
          }
          return acc;
        },
        []
      );

      return dataFunnelActivitiesToSalesMapped.map((activity) => {
        return {
          ...activity,
          x: activity.activityResultTypeName,
          y: activity.totalActivities,
          text: activity.displayText,
        };
      });
    },
    dataFunnelActivitiesToSalesFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.dataFunnelActivitiesToSales.filter((el) =>
          this.filterCollaborators.includes(el.collaboratorId)
        );
      } else {
        return this.dataFunnelActivitiesToSales;
      }
    },

    dataActivitiesBySourceTypes() {
      let dataActivitiesBySourceTypesMapped = [];
      dataActivitiesBySourceTypesMapped = this.activitiesList.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex(
            (el) => el.sourceTypeId == obj.activitySourceType.id
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              sourceTypeId: obj.activitySourceType.id,
              sourceTypeName: obj.activitySourceType.label,
              collaboratorId: obj.collaboratorId,
              collaboratorName: this.collaboratorsListFilteredByUseCRM.find(
                (el) => el.id == obj.collaboratorId
              )
                ? this.collaboratorsListFilteredByUseCRM.find(
                    (el) => el.id == obj.collaboratorId
                  ).displayLabel
                : "Collaborateur non identifié",
              displayText: "1 contact utile",
              totalActivities: 1,
            });
          } else {
            // On additionne
            acc[findIndex]["totalActivities"] += 1;
            acc[findIndex]["displayText"] =
              acc[findIndex]["totalActivities"] > 1
                ? acc[findIndex]["totalActivities"] + " contacts utiles"
                : acc[findIndex]["totalActivities"] + " contact utile";
          }
          return acc;
        },
        []
      );
      // let countAllActivites = 0;
      // for (let i = 0; i < dataActivitiesByChanelTypesMapped.length; i++ ) {
      //     countAllActivites += dataActivitiesByChanelTypesMapped[i].totalActivities;
      // }

      return dataActivitiesBySourceTypesMapped.map((activity) => {
        return {
          ...activity,
          x: activity.sourceTypeName,
          y: activity.totalActivities,
          text: activity.sourceTypeName,
          fill: "#EBEA00",
        };
      });
    },
    dataActivitiesBySourceTypesFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.dataActivitiesBySourceTypes.filter((el) =>
          this.filterCollaborators.includes(el.collaboratorId)
        );
      } else {
        return this.dataActivitiesBySourceTypes;
      }
    },

    dataActivitiesByChanelTypes() {
      let dataActivitiesByChanelTypesMapped = [];
      dataActivitiesByChanelTypesMapped = this.activitiesList.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex(
            (el) => el.chanelTypeId == obj.activityChanelType.id
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              chanelTypeId: obj.activityChanelType.id,
              chanelTypeName: obj.activityChanelType.label,
              collaboratorId: obj.collaboratorId,
              collaboratorName: this.collaboratorsListFilteredByUseCRM.find(
                (el) => el.id == obj.collaboratorId
              )
                ? this.collaboratorsListFilteredByUseCRM.find(
                    (el) => el.id == obj.collaboratorId
                  ).displayLabel
                : "Collaborateur non identifié",
              displayText: "1 contact utile",
              totalActivities: 1,
            });
          } else {
            // On additionne
            acc[findIndex]["totalActivities"] += 1;
            acc[findIndex]["displayText"] =
              acc[findIndex]["totalActivities"] > 1
                ? acc[findIndex]["totalActivities"] + " contacts utiles"
                : acc[findIndex]["totalActivities"] + " contact utile";
          }
          return acc;
        },
        []
      );
      // let countAllActivites = 0;
      // for (let i = 0; i < dataActivitiesByChanelTypesMapped.length; i++ ) {
      //     countAllActivites += dataActivitiesByChanelTypesMapped[i].totalActivities;
      // }

      return dataActivitiesByChanelTypesMapped.map((activity) => {
        return {
          ...activity,
          x: activity.chanelTypeName,
          y: activity.totalActivities,
          text: activity.chanelTypeName,
          fill: "#EBEA00",
        };
      });
    },
    dataActivitiesByChanelTypesFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.dataActivitiesByChanelTypes.filter((el) =>
          this.filterCollaborators.includes(el.collaboratorId)
        );
      } else {
        return this.dataActivitiesByChanelTypes;
      }
    },

    dataActivitiesByCollaborators() {
      let dataActivitiesByCollaboratorsMapped = [];
      dataActivitiesByCollaboratorsMapped = this.activitiesList.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex(
            (el) => el.collaboratorId == obj.collaboratorId
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              collaboratorId: obj.collaboratorId,
              collaboratorName: this.collaboratorsListFilteredByUseCRM.find(
                (el) => el.id == obj.collaboratorId
              )
                ? this.collaboratorsListFilteredByUseCRM.find(
                    (el) => el.id == obj.collaboratorId
                  ).displayLabel
                : "Collaborateur non identifié",
              displayText: "1 contact utile",
              totalActivities: 1,
            });
          } else {
            // On additionne
            acc[findIndex]["totalActivities"] += 1;
            acc[findIndex]["displayText"] =
              acc[findIndex]["totalActivities"] > 1
                ? acc[findIndex]["totalActivities"] + " contacts utiles"
                : acc[findIndex]["totalActivities"] + " contact utile";
          }
          return acc;
        },
        []
      );

      return dataActivitiesByCollaboratorsMapped.map((activity) => {
        return {
          ...activity,
          x: activity.collaboratorName,
          y: activity.totalActivities,
          text: activity.displayText,
          fill: "#498fff",
        };
      });
    },
    dataActivitiesByCollaboratorsFiltered() {
      if (this.filterCollaborators.length > 0) {
        return this.dataActivitiesByCollaborators.filter((el) =>
          this.filterCollaborators.includes(el.collaboratorId)
        );
      } else {
        return this.dataActivitiesByCollaborators;
      }
    },
  },
  async created() {
    this.getAffairs({});
    this.getCompanies({});
    this.getCollaborators({});

    if (
      this.userParametersList.find((el) => el.key == "homePage") &&
      this.userParametersList.find((el) => el.key == "homePage").value ==
        this.$route.path
    ) {
      this.currentHomePage = true;
    } else {
      this.currentHomePage = false;
    }
    if (
      this.userParametersList.find((el) => el.key == "dashboardCRM") &&
      this.userParametersList.find((el) => el.key == "dashboardCRM") &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardCRM").value
      ) &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardCRM").value
      ).panels
    ) {
      this.panels = JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardCRM").value
      ).panels;
    } else {
      this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
    }
  },
  async activated() {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
    this.keyRefresh++;
    this.dashboardLoaded = true;
    await this.initDashboard();
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "getActivities",
      "getInvoices",
      "getQuotes",
      "getOrderFormCustomers",
      "getCompanies",
      "getCollaborators",
      "getAffairs",
    ]),
    formatNumber,
    formatCurrency,
    replaceNbsps,
    setHomePage() {
      this.createOrUpdateUserParameter({
        label: "Page d'accueil",
        key: "homePage",
        value: this.$route.path,
      });
      this.currentHomePage = true;
    },
    checkModule(moduleId) {
      return true;
    },
    resizeHandler(e) {
      let newAspectRatio = 0;

      if (window.innerWidth >= 1500) {
        newAspectRatio = 1.45;
      } else if (window.innerWidth >= 1350) {
        newAspectRatio = 1.3;
      } else if (window.innerWidth >= 1200) {
        newAspectRatio = 1;
      } else if (window.innerWidth >= 992) {
        newAspectRatio = 0.8;
      } else if (window.innerWidth >= 850) {
        newAspectRatio = 8;
      } else if (window.innerWidth >= 768) {
        newAspectRatio = 7;
      } else if (window.innerWidth >= 576) {
        newAspectRatio = 7;
      } else if (window.innerWidth < 576) {
        newAspectRatio = 5;
      }

      if (this.aspectRatio !== newAspectRatio) {
        this.aspectRatio = newAspectRatio;
        this.$forceUpdate();
      }
    },
    onChangeFilterCollaborators(args) {
      this.filterCollaboratorsUserIds = [];
      args.value.forEach((value) => {
        if (
          this.collaboratorsListFilteredByUseCRM.find((el) => el.id == value) &&
          this.collaboratorsListFilteredByUseCRM.find((el) => el.id == value)
            .userId
        ) {
          this.filterCollaboratorsUserIds.push(
            this.collaboratorsListFilteredByUseCRM.find((el) => el.id == value)
              .userId
          );
        }
      });
    },
    async changeTab(tab) {
      this.tabActive = tab;
      this.tagsSelected = [];
      this.$forceUpdate();
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setStatusList(val) {
      this.statusList = val;
    },
    async initDashboard() {
      this.dataLoaded = false;
      let filter = this.userParametersList.find(
        (el) => el.key == "dashboardCRMFilters"
      );
      if (filter) {
        if (JSON.parse(filter.value).dateRange) {
          this.setDates(JSON.parse(filter.value).dateRange, false);
        } else {
          this.setDates(
            {
              startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
              endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
            },
            false
          );
        }
      } else {
        await this.setDates(
          {
            startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
          },
          false
        );
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        this.dataLoaded = false;

        this.dateRange = {
          startDate: new Date(dayjs(dates.startDate).format("YYYY-MM-DD")),
          endDate: dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD")),
        };
        this.dateRangeDefault = [
          this.dateRange.startDate,
          this.dateRange.endDate,
        ];

        let allPromises = [
          this.getActivities({
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          }),
          this.getInvoices({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
          this.getQuotes({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
          this.getOrderFormCustomers({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
        ];

        Promise.all(allPromises).then(() => {
          this.dataLoaded = true;
        });
      }
    },
    selectParamerterAction(args) {
      if (args.item.type == "enableEditDashboard") {
        this.editDashboard = true;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "enableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Désactiver l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-border-outer",
          type: "disableEditDashboard",
        });
      } else if (args.item.type == "disableEditDashboard") {
        this.editDashboard = false;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "disableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        });
      } else if (args.item.type == "dashboardCRM") {
        this.dashboardLoaded = false;
        this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord CRM",
          key: "dashboardCRM",
          value: JSON.stringify({ panels: this.panels }),
        });
        this.$nextTick(() => {
          this.dashboardLoaded = true;
        });
        this.$forceUpdate();
      }
    },
    onChangeResizeStart() {
      this.updatePanelSize(false);
    },
    onChangeResizeStop() {
      this.updatePanelSize(true);
    },
    onCreatedDashboard(args) {
      this.updatePanelSize(true);
    },
    updatePanelSize(loaded = true) {
      var panelElement = document.querySelectorAll(".e-panel");
      for (var i = 0; i < panelElement.length; i++) {
        this.panels.find((el) => el.id == panelElement[i].id).loaded = false;
        this.panels.find((el) => el.id == panelElement[i].id).height =
          panelElement[i].offsetHeight;
        if (loaded) {
          this.panels.find((el) => el.id == panelElement[i].id).loaded = true;
        }
      }
      this.$forceUpdate();
      if (loaded) {
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord CRM",
          key: "dashboardCRM",
          value: JSON.stringify({
            panels: this.$refs.DashbordInstance.serialize(),
          }),
        });
      }
    },
    onCloseIconHandler(panelId) {
      this.$refs.DashbordInstance.removePanel(panelId);
      this.$forceUpdate();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";

.e-panel-container {
  .btn-close {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 0px;
    z-index: 2;
  }
  &:hover {
    .btn-close {
      display: block;
      right: 1rem;
    }
  }
}
.e-dashboardlayout.e-control .cursor-default {
  cursor: default !important;
}
/* DashboardLayout element styles  */
// .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
//     display: block;
// }

.e-dashboardlayout.e-control .e-panel {
  border-radius: 5px;
}

.e-panel .e-gridheader {
  border-top: 0px !important;
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}
.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.dashboard-default #defaultLayout .e-panel {
  z-index: 2 !important;
  box-shadow: none !important;
  &:hover {
    border: 1px #e0e0e0 solid;
  }
  &.active {
    z-index: 1000 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
  &.box-shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
    cursor: pointer !important;
  }
  &.active:hover {
    border: 1px #bdbdbd solid;
  }
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}

.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
</style>
